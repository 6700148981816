// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'

import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import Division from '@bodhi-project/components/lib/division'
import '@bodhi-project/components/lib/division/style.less'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/4.10.3/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/4.10.3/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import StandardPageWrapper from '../components/standard-page-wrapper'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Credits',
  nakedPageSlug: '404',
  pageAbstract: 'Our partners and collaborators',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    prismadesignCover: file(relativePath: { eq: "prismadesign.jpg" }) {
      ...max1500
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <ConfigProvider prefixCls="wtf">
    <StandardPageWrapper className="" seoData={seoData} {...props}>
      <h1 className="as-h2">Credits</h1>
      <p style={{ maxWidth: '45rem' }}>
        Founded by Franz Fassbender in 1988, Prisma has patiently documented
        with words and pictures the evolving, creative spirit and its manifest
        in a myriad of forms for the past three decades here in Auroville,
        India. Over these years a network of trusted services and partners have
        emerged that we have relied on to print and publish our works.
      </p>
      <p style={{ marginBottom: '2rem', maxWidth: '45rem' }}>
        We love independent artists, creators and publishers, and we often see
        them struggle with the same challenges every time as us, so we offer
        those who need it, the access to our own network of printers, creators
        and service providers.
      </p>
      <div>
        <Division Row={Row} Col={Col}>
          <div>
            <h2>#1. Prisma Design</h2>
            <div className="as-paragraph">
              <Img
                fluid={props.data.prismadesignCover.childImageSharp.fluid}
                durationFadeIn={getRandomArbitraryInt(150, 450)}
              />
            </div>
            <p>
              Prisma Design is a multiverse project set in Auroville, south
              India, bringing together design, technology and creative
              imagination for a luminous future. Prisma Design is incubated by
              Prisma, and housed in Aurelec, Auroville.
            </p>
            <p>
              <Link className="arrow" to="https://design.prisma.haus">
                Visit their website&nbsp;&nbsp;
                <span className="points-northeast">∆</span>
              </Link>
            </p>
          </div>
          <div>&nbsp;</div>
        </Division>
      </div>
    </StandardPageWrapper>
  </ConfigProvider>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
